<template>
  <v-card>
    <v-card-title class='px-2'>
      {{ $t('Log PO') }}
      <v-spacer></v-spacer>
      <v-btn
        color='primary'
        :loading='exportLoading'
        :disabled='exportLoading'
        @click='exportExcel'
      >
        {{ $t('excel') }}
      </v-btn>
    </v-card-title>
    <Search2 @onSendDate='searchLog' />
    <v-data-table
      :headers='columns'
      :items='dataTableList'
      :options.sync='options'
      :server-items-length='totalDataTableList'
      :footer-props='footer'
      :loading='loading'
      disable-sort
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.id`]='{ index}'>
        <span class='me-2'>{{ segmentId + index + 1 }}</span>
      </template>
      <template #item.log_status_id='{ item }'>
        <span v-if='item.log_status_id ==1'>{{ $t('add') }}</span>
        <span v-if='item.log_status_id ==2'>{{ $t('edit') }}</span>
        <span v-if='item.log_status_id ==3'>{{ $t('delete') }}</span>
      </template>
      <template #item.purchaseorder_cost='{ item }'>
        {{ item.purchaseorder_cost | formatPrice }}
      </template>
      <template #item.purchaseorder_total='{ item }'>
        {{ item.purchaseorder_total | formatPrice }}
      </template>
      <template #item.purchaseorder_vat='{ item }'>
        {{ item.purchaseorder_vat }}%
      </template>
      <template v-slot:[`item.log_time`]='{ item }'>
        <ConversDateTime :datetime='item.log_time' />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import Search2 from './Search2.vue'
import { i18n } from '@/plugins/i18n'
import logs from '@/api/logs/logs'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import { formatPrice } from '@/plugins/filters'

export default {
  components: {
    Search2,
    ConversDateTime,

  },
  filters: {
    formatPrice,
  },
  setup() {
    const XLSX = require('xlsx')
    const loading = ref(false)
    const options = ref({})
    const payloadData = ref({})
    const dataTableList = ref([])
    const totalDataTableList = ref(0)
    const segmentId = ref(0)
    const columns = ref([
      {
        text: '#',
        value: 'id',
        width: 50,
      },
      {
        text: i18n.t('a_number'),
        value: 'purchase_id',
        width: 100,
      },
      {
        text: i18n.t('drug'),
        value: 'purchaseorder_drug_id',
        width: 100,
      },
      {
        text: i18n.t('list'),
        value: 'purchaseorder_drug_name',
        width: 200,
      },
      {
        text: i18n.t('amount'),
        value: 'purchaseorder_amount',
        width: 100,
        align: 'right',
      },
      {
        text: i18n.t('unit'),
        value: 'purchaseorder_drug_unit',
        width: 100,
      },
      {
        text: i18n.t('cost_price'),
        value: 'purchaseorder_cost',
        width: 100,
        align: 'right',
      },
      {
        text: i18n.t('total_price'),
        value: 'purchaseorder_total',
        width: 100,
        align: 'right',
      },
      {
        text: i18n.t('tax'),
        value: 'purchaseorder_vat',
        width: 100,
        align: 'right',
      },
      {
        text: i18n.t('status'),
        value: 'log_status_id',
        width: 100,
        align: 'right',
      },
      {
        text: i18n.t('user'),
        value: 'user_fullname',
        width: 200,
      },
      {
        text: i18n.t('date'),
        value: 'log_time',
        width: 180,
      },
    ])
    const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
    const exportLoading = ref(false)

    const searchLog = payload => {
      loading.value = true
      payloadData.value = payload
      logs.logpo({
        ...payload,
        active_page: options.value.page,
        per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
      }).then(res => {
        const {
          data, count, segment,
        } = res
        dataTableList.value = data
        totalDataTableList.value = count
        segmentId.value = segment
        loading.value = false
      })
    }

    watch([options], () => {
      searchLog(payloadData.value)
    })

    const exportExcel = () => {
      exportLoading.value = true
      logs.logpo({
        ...payloadData.value,
        active_page: 1,
        per_page: '0',
      }).then(res => {
        const dataExport = res.data.map((item, i) => {
          delete item.member_card_price
          delete item.member_discount
          delete item.member_name
          delete item.member_point

          return { ...item, number: i + 1 }
        })
        const fileName = `${i18n.t('Log PO')}.xlsx`
        const Heading = [
          [
            `${i18n.t('order_history')}`,
          ],
          [
            '#',
            `${i18n.t('user')}`,
            `${i18n.t('list')}`,
            'IP',
            'Browser',
            `${i18n.t('date')}`,
          ],
        ]
        const ws = XLSX.utils.aoa_to_sheet(Heading)
        XLSX.utils.sheet_add_json(ws, dataExport, {
          header: [
            'number',
            'user_fullname',
            'log_text',
            'log_ip_address',
            'log_browser',
            'log_time',
          ],
          skipHeader: true,
          origin: -1,
        })
        const wb = XLSX.utils.book_new()
        const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 5 } }]
        const wscols = [
          { wch: 8 },
          { wch: 30 },
          { wch: 80 },
          { wch: 30 },
          { wch: 30 },
          { wch: 30 },
        ]
        ws['!merges'] = merge
        ws['!cols'] = wscols
        XLSX.utils.book_append_sheet(
          wb,
          ws,
          `${i18n.t('Log PO')}`,
        )

        XLSX.writeFile(wb, fileName)
        setTimeout(() => {
          exportLoading.value = false
        }, 1000)
      })
    }

    return {
      loading,
      options,
      payloadData,
      dataTableList,
      totalDataTableList,
      segmentId,
      columns,
      footer,
      exportLoading,
      exportExcel,
      searchLog,
    }
  },
}
</script>
