var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$t('Log PO'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.exportLoading,"disabled":_vm.exportLoading},on:{"click":_vm.exportExcel}},[_vm._v(" "+_vm._s(_vm.$t('excel'))+" ")])],1),_c('Search2',{on:{"onSendDate":_vm.searchLog}}),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"server-items-length":_vm.totalDataTableList,"footer-props":_vm.footer,"loading":_vm.loading,"disable-sort":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var index = ref.index;
return [_c('span',{staticClass:"me-2"},[_vm._v(_vm._s(_vm.segmentId + index + 1))])]}},{key:"item.log_status_id",fn:function(ref){
var item = ref.item;
return [(item.log_status_id ==1)?_c('span',[_vm._v(_vm._s(_vm.$t('add')))]):_vm._e(),(item.log_status_id ==2)?_c('span',[_vm._v(_vm._s(_vm.$t('edit')))]):_vm._e(),(item.log_status_id ==3)?_c('span',[_vm._v(_vm._s(_vm.$t('delete')))]):_vm._e()]}},{key:"item.purchaseorder_cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.purchaseorder_cost))+" ")]}},{key:"item.purchaseorder_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.purchaseorder_total))+" ")]}},{key:"item.purchaseorder_vat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.purchaseorder_vat)+"% ")]}},{key:"item.log_time",fn:function(ref){
var item = ref.item;
return [_c('ConversDateTime',{attrs:{"datetime":item.log_time}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }